// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animation-design-js": () => import("/opt/build/repo/src/pages/animation-design.js" /* webpackChunkName: "component---src-pages-animation-design-js" */),
  "component---src-pages-graphic-design-condo-concierge-js": () => import("/opt/build/repo/src/pages/graphic-design/condo-concierge.js" /* webpackChunkName: "component---src-pages-graphic-design-condo-concierge-js" */),
  "component---src-pages-graphic-design-cumberland-js": () => import("/opt/build/repo/src/pages/graphic-design/cumberland.js" /* webpackChunkName: "component---src-pages-graphic-design-cumberland-js" */),
  "component---src-pages-graphic-design-green-cities-js": () => import("/opt/build/repo/src/pages/graphic-design/green-cities.js" /* webpackChunkName: "component---src-pages-graphic-design-green-cities-js" */),
  "component---src-pages-graphic-design-halseonne-js": () => import("/opt/build/repo/src/pages/graphic-design/halseonne.js" /* webpackChunkName: "component---src-pages-graphic-design-halseonne-js" */),
  "component---src-pages-graphic-design-innofit-js": () => import("/opt/build/repo/src/pages/graphic-design/innofit.js" /* webpackChunkName: "component---src-pages-graphic-design-innofit-js" */),
  "component---src-pages-graphic-design-lotus-herring-js": () => import("/opt/build/repo/src/pages/graphic-design/lotus-herring.js" /* webpackChunkName: "component---src-pages-graphic-design-lotus-herring-js" */),
  "component---src-pages-graphic-design-monster-factory-js": () => import("/opt/build/repo/src/pages/graphic-design/monster-factory.js" /* webpackChunkName: "component---src-pages-graphic-design-monster-factory-js" */),
  "component---src-pages-graphic-design-salt-wit-js": () => import("/opt/build/repo/src/pages/graphic-design/salt-wit.js" /* webpackChunkName: "component---src-pages-graphic-design-salt-wit-js" */),
  "component---src-pages-illustration-js": () => import("/opt/build/repo/src/pages/illustration.js" /* webpackChunkName: "component---src-pages-illustration-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

